import { NewsTable } from "./views/NewsTable";
import { QuestionItem } from "./views/QuestionItem";
import TheHeader from "./views/TheHeader";
import { WindowWatcher } from "../umadash.js/browser/WindowWatcher";
import SmoothScroll from "../umadash.js/jquery/utils/SmoothScroll";
import Slideshow from "../umadash.js/jquery/views/Slideshow";
import { SectionWatcher } from "../umadash.js/jquery/utils/SectionWatcher";
import { TargetAnchors } from "./views/TargetAnchors";
import { SliderWrapper } from "./views/SliderWrapper";
import Event from "../umadash.js/events/Event";

const $ = jQuery;
const _ = require("lodash");
const WebFont = require("webfontloader");

export class EntryIndex {
  constructor() {
    SmoothScroll.getInstance().attach();
    TheHeader.getInstance().setup();
    this.sliders = [];

    {
      this.newsTables = [];
      const elements: JQuery = $(".NewsTable");
      if (elements.length > 0) {
        elements.each((index: number, element: HTMLElement) => {
          this.newsTables.push(new NewsTable($(element)));
        });
      }
    }

    {
      this.questionItems = [];
      const elements: JQuery = $(".QuestionItem");
      if (elements.length > 0) {
        elements.each((index: number, element: HTMLElement) => {
          this.questionItems.push(new QuestionItem($(element)));
        });
      }
    }

    {
      const elements: JQuery = $(".Slideshow");
      this.slideshows = [];
      if (elements.length > 0) {
        elements.each((index: number, element: HTMLElement) => {
          this.slideshows.push(new Slideshow($(element)));
        });
      }
    }

    {
      const elements: JQuery = $(".Target");
      const anchors: JQuery = $(".TargetAnchors");
      if (elements.length > 0) {
        this.sectionWatcher = new SectionWatcher(elements);
        this.sectionWatcher.addEventListener(SectionWatcher.Change, () => {
          const currentIndex: number = this.sectionWatcher.getCurrentIndex();
          for (let i: number = 0; i < this.targetAnchors.length; i += 1) {
            const value: TargetAnchors = this.targetAnchors[i];
            value.setCurrentIndex(currentIndex);
          }
        });

        this.targetAnchors = [];
        anchors.each((index: number, element: HTMLElement) => {
          this.targetAnchors.push(new TargetAnchors($(element)));
        });
      }
    }

    SmoothScroll.getInstance().addEventListener(SmoothScroll.Move, () => {
      TheHeader.getInstance().close();
    });

    WindowWatcher.getInstance().addEventListener(WindowWatcher.HorizontalResize, _.debounce(this.resizeHandler, 200));
    WindowWatcher.getInstance().addEventListener(WindowWatcher.Scroll, this.scrollHandler);
  }

  public run(): void {
    WebFont.load({
      google: {
        families: ["Noto Sans JP"]
      },
      active: () => {
        {
          const elements: JQuery = $(".hasSlider");

          if (elements.length > 0) {
            elements.each((index: number, element: HTMLElement) => {
              const $element = $(element);
              const $slider = $element.find(".Slider");
              const $btnLeft = $element.find(".BtnArrow.left");
              const $btnRight = $element.find(".BtnArrow.right");
              const slider = new SliderWrapper($slider, $btnLeft, $btnRight);
              slider.show();
              this.sliders.push(slider);
            });

            this.sliderPositionWatcher = new SectionWatcher(elements);
            this.sliderPositionWatcher.addEventListener(SectionWatcher.Change, (e: Event) => {
              if (this.prevSlider) this.prevSlider.setEnableKeyboardControl(false);

              const currentIndex: number = this.sliderPositionWatcher.getCurrentIndex();
              const slider: SliderWrapper = this.sliders[currentIndex];
              slider.setEnableKeyboardControl(true);
              this.prevSlider = slider;
            });
          }

          this.resizeHandler();
        }
      }
    });

    for (let i: number = 0; i < this.newsTables.length; i += 1) {
      const element: NewsTable = this.newsTables[i];
      element.start();
    }

    for (let i: number = 0; i < this.slideshows.length; i += 1) {
      const element: Slideshow = this.slideshows[i];
      element.start();
    }

    WindowWatcher.getInstance().start();
    this.resizeHandler();
    this.scrollHandler();
  }

  private resizeHandler = () => {
    TheHeader.getInstance().resize();
    SmoothScroll.getInstance().setOffset(0);

    const length: number = this.sliders.length;
    for (let i: number = 0; i < length; i += 1) {
      const element: SliderWrapper = this.sliders[i];
      element.resize();
    }
  };

  private scrollHandler = () => {
    const scrollY: number = window.scrollY;
    if (this.sectionWatcher) this.sectionWatcher.updateByScroll(scrollY);
    if (this.sliderPositionWatcher) this.sliderPositionWatcher.updateByScroll(scrollY);
  };

  private newsTables: NewsTable[];
  private questionItems: QuestionItem[];
  private slideshows: Slideshow[];
  private sliders: SliderWrapper[];
  private sectionWatcher: SectionWatcher;
  private targetAnchors: TargetAnchors[];
  private prevSlider: SliderWrapper;

  private sliderPositionWatcher: SectionWatcher;
}
