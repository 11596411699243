const $ = jQuery;

import DOMSlider from "../../umadash.js/jquery/views/DOMSlider";
import DOMRecycleViewItem from "../../umadash.js/jquery/views/DOMRecycleViewItem";
import Command from "../../umadash.js/commands/Command";
import JqueryUtil from "../../umadash.js/jquery/utils/JqueryUtil";
import { Easing, EasingFunction } from "../../umadash.js/tween/Easing";
import { KeyboardWatcher, KeyboardEvent, IKeyboardEventData } from "../../umadash.js/browser/KeyboardWatcher";
import { KeyCode } from "../../umadash.js/browser/KeyCode";

export class SliderWrapper {
  constructor($slider: JQuery, $btnLeft: JQuery, $btnRight: JQuery) {
    this.slider = new SimpleSlider($slider, 16, 500, Easing.easeInOutQuart, () => {
      return new SliderItem();
    });

    $btnRight.on("click", () => {
      this.slider.moveLeft();
    });

    $btnLeft.on("click", () => {
      this.slider.moveRight();
    });
  }

  public setEnableKeyboardControl(enable: boolean): void {
    KeyboardWatcher.getInstance().removeAllEventListener();

    if (enable) {
      KeyboardWatcher.getInstance().addEventListener(KeyboardEvent.KeyDown, (e: KeyboardEvent) => {
        switch (e.getKeyCode()) {
          case KeyCode.LeftArrow:
            this.slider.moveRight();
            break;

          case KeyCode.RightArrow:
            this.slider.moveLeft();
            break;
        }
      });
      KeyboardWatcher.getInstance().start();
    }
  }

  public show(): void {
    this.slider.show();
  }

  public resize(): void {
    this.slider.resize();
  }

  private slider: SimpleSlider<SliderItem>;
}

class SimpleSlider<T extends DOMRecycleViewItem> extends DOMSlider<T> {
  constructor($elm: JQuery, margin: number, duration: number, easing: EasingFunction, onRequireItem: () => T) {
    super($elm, margin, duration, easing, onRequireItem);
  }

  protected impGetShowCommand(): Command {
    return JqueryUtil.fadeIn(this.getView(), 0);
  }

  protected impGetHideCommand(): Command {
    return JqueryUtil.fadeOut(this.getView(), 0);
  }
}

class SliderItem extends DOMRecycleViewItem {
  constructor() {
    super();
  }

  protected impDidSetup(): void {}
  protected impWillDestroy(): void {}
}
